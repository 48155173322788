import axios from '@/axios.js'

export default {
    getTracks: async (resortUuid, parameters) => {
        try {
            const response = await axios.editorapi.get(`resorts/v3/${resortUuid}/tracks`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    exportTracks: async (resortUuid, parameters) => {
        try {
            const response = await axios.editorapi.get(`resorts/v3/export/${resortUuid}/tracks`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
}
